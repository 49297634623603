
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import {
  DisableFieldAutofill,
  DisableFormAutofill,
  OpenCenteredWindow
} from '@/helpers/WebHelper';
import { CustomerService } from '@/services/customer-service';
import { Address } from '@/store/models/AddressModel';
import { CustomerPaymentModel, PaymentProfile } from '@/store/models/PaymentModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import PaymentAdd from '@/components/PaymentAdd.vue';
import PaymentSelect from '@/components/PaymentSelect.vue';
import PaymentSelectCard from '@/components/cardComponents/PaymentSelectCard.vue';
import { ConstValues } from '@/ConstValues';
import { Confirm } from '@/helpers/AuthHelper';
import SelectPaymentDialog from './SelectPaymentDialog.vue';
import { PaymentService } from '@/services/payment-service';
import { Getter } from 'vuex-class';
import { Customer } from '@/store/models/CustomerModel';
@Component({
  components: {
    DefaultLayout,
    PaymentAdd,
    SelectPaymentDialog,
    PaymentSelect,
    PaymentSelectCard
  }
})
export default class PaymentTerms extends Vue {
  $refs!: {
    PaymentAdd: HTMLFormElement;
    SelectPaymentDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop() isSelectingPayment!: boolean;
  @Prop({ default: 520 }) cardWidth!: number;
  @Prop({ default: true }) isFlat!: boolean;
  @Prop({ default: true }) canChangePaymentMethod!: boolean;
  @Prop() alternativePaymentProfileId!: string;
  @Prop() shouldShowBreakdown!: boolean;
  @Prop() shouldNotShowTerms!: boolean;
  @Prop() isInvoice!: boolean;
  /* Store Actions */
  @Getter('getCustomer', { namespace: 'customer' }) Customer: Customer;
  /* Watchers */
  @Watch('defaultPaymentProfile')
  onDefaultPaymentProfileChange() {
    if (this.defaultPaymentProfile)
      this.$emit('paymentProfile', this.defaultPaymentProfile);
  }
  /* Data */
  shouldOpenPaymentDialog: boolean = false;
  shouldShowPaymentOptions: boolean = false;
  isNewPaymentFormValid: boolean = false;
  hasNotFoundPaymentProfile: boolean = false;
  isLoading: boolean = false;
  isAddingNewPayment: boolean = false;
  isGettingPaymentProfiles: boolean = false;
  isSavingBillingAddress: boolean = false;
  paymentProfiles: PaymentProfile[] = [];
  defaultPaymentProfile: PaymentProfile = null;
  billingAddresses: any[] = [];
  newPaymentModel: CustomerPaymentModel = new CustomerPaymentModel();
  newPaymentException: string = '';
  paymentOptions: string[] = ['Set as Default', 'Delete'];
  isAlreadyDefault: boolean = false;
  isFormValid: boolean = false;
  shouldShowNewBillingAddressDialog: boolean = false;
  addressToEdit: Address = new Address();
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  selectedPaymentProfileIndex: number | null = null;
  isLinkingCardToInvoice: boolean = false;
  /* Utility Functions */
  onPaymentProfileChange(profile: PaymentProfile) {
    if (this.isSelectingPayment) {
      this.defaultPaymentProfile = profile;
    }
    if (this.isLinkingCardToInvoice) {
      Confirm(
        async () => {
          try {
            await CustomerService.UpdateCustomerDefaultCCForTerms(
              profile.PaymentProfileId
            );
            this.GetCustomerPaymentProfiles();
            SetSnackBar('Successfully updated your default payment method');
          } catch (err) {
            SetSnackBar(
              'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
            );
          }
        },
        'Confirm Default Invoice Payment',
        'Linking this card will automatically charge your account on the day your Invoice is due. Are you sure you would like to continue?',
        'Never mind',
        'I am sure'
      );
      this.isLinkingCardToInvoice = false;
    }
    this.$refs.SelectPaymentDialog.closeDialog();
  }
  async removeCustomerDefaultCCForTerms() {
    Confirm(
      async () => {
        try {
          await CustomerService.UpdateCustomerDefaultCCForTerms('');
          SetSnackBar('Default credit card unlinked');
          this.GetCustomerPaymentProfiles();
        } catch (err) {
          SetSnackBar(
            'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
          );
        }
      },
      'Unlink Default Invoice Payment Removal',
      "This card will no longer be used to automatically pay invoices upon it's designated due date. Are you sure you would like to continue?",
      'Never mind',
      'I am sure'
    );
  }

  closeNewPaymentDialog() {
    this.newPaymentModel = new CustomerPaymentModel();
    this.$refs.PaymentAdd.closeDialog();
  }
  openPaymentMethodDialog(isLinkingToInvoice: boolean = false) {
    this.isLinkingCardToInvoice = isLinkingToInvoice;
    this.$refs.SelectPaymentDialog.openDialog(!isLinkingToInvoice);
    this.isAlreadyDefault = false;
  }
  onDefaultPaymentUpdated(profile: PaymentProfile) {
    this.defaultPaymentProfile = profile;
    this.GetCustomerPaymentProfiles();
  }
  /* Loaders */
  async GetStates() {
    const response = await CustomerService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const response = await CustomerService.GetCountries();
    this.countries = response.data;
    this.addressToEdit.Country = this.countries[0];
  }
  async GetCustomerPaymentProfiles() {
    this.isGettingPaymentProfiles = true;
    try {
      this.hasNotFoundPaymentProfile = false;
      const response = await PaymentService.GetCustomerPaymentProfiles();
      if (response.data && response.data.length > 0) {
        this.paymentProfiles = response.data.map((d: any) => new PaymentProfile(d));
      } else {
        this.paymentProfiles = [];
        this.defaultPaymentProfile = null;
        this.$refs.SelectPaymentDialog.closeDialog();
      }
      if (this.paymentProfiles.length == 0) this.hasNotFoundPaymentProfile = true;
      this.HandlePaymentProfileOptions();
    } catch (error) {
      // console.log(error);
    }
    setTimeout(() => {
      this.isGettingPaymentProfiles = false;
    }, 500);
    // this.isGettingPaymentProfiles = false;
  }
  HandlePaymentProfileOptions() {
    if (this.shouldNotShowTerms) {
      const index = this.paymentProfiles.findIndex(p => p.CardType === 7);
      if (index > -1) {
        this.paymentProfiles.splice(index, 1);
      }
    }
    this.paymentProfiles.forEach(p => {
      p.CardName = this.getCardName(p);
      if (p.CardType == 8 || p.CardType == -1)
        p.Avatar = require('@/assets/Payment/bank.png');
      else p.Avatar = require(`@/assets/Payment/${p.CardName}.png`);
    });
    if (this.alternativePaymentProfileId) {
      this.defaultPaymentProfile = this.paymentProfiles.find(
        p => p.PaymentProfileId == this.alternativePaymentProfileId
      );
      this.defaultPaymentProfile.Avatar = require(`@/assets/Payment/${this.getCardName(
        this.defaultPaymentProfile
      )}.png`);
    } else if (this.isInvoice) {
      const defaultProfile = this.paymentProfiles.find(
        p => p.IsDefaultPaymentMethodForInvoices
      );
      this.defaultPaymentProfile = defaultProfile || this.paymentProfiles[0];
      if (this.defaultPaymentProfile) this.defaultPaymentProfile.IsDefault = true;
    } else {
      this.defaultPaymentProfile =
        this.paymentProfiles.find(p => p.IsDefault) || this.paymentProfiles[0];
    }
    if (
      this.isInvoice &&
      this.paymentProfiles.filter(p => p.CardType !== 7).length == 0
    ) {
      this.hasNotFoundPaymentProfile = true;
    }
  }
  async saveNewBillingAddress() {
    this.isSavingBillingAddress = true;
    try {
      let model = ConversionHelper.convertAddressToAddressModel(this.addressToEdit, true);
      const response = await CustomerService.AddNewBillingAddress(model);
      SetSnackBar('Address added successfully');
      this.GetBillingAddressesCondensed(response.data.Id);
      this.shouldShowNewBillingAddressDialog = false;
    } catch (err) {
      SetSnackBar('There was an error saving your billing address');
    }
    this.isSavingBillingAddress = false;
  }
  async GetBillingAddressesCondensed(addressId: string = '') {
    const response = await CustomerService.GetBillingAddresses();
    this.billingAddresses = response.data;
    if (addressId) this.newPaymentModel.BillingAddressId = addressId;
  }
  openNewPaymentDialog() {
    // this.GetBillingAddressesCondensed();
    // this.$refs.PaymentAdd.openNewPaymentDialog();
    this.addNewPaymentSelected();
  }
  async openNewAddressDialog() {
    this.addressToEdit = new Address();
    await this.GetStates();
    await this.GetCountries();
    this.shouldShowNewBillingAddressDialog = true;
    setTimeout(() => {
      DisableFieldAutofill('state');
    }, 500);
  }
  isValidDate(date: string) {
    if (!date) return false;
    let [month, year] = date.split('/');
    const todaysDate = new Date();
    if (year.length > 2) year = year.substring(2);
    if (`20${year}` === todaysDate.getFullYear().toString())
      return parseInt(month) >= todaysDate.getMonth() + 1;
    if (`20${year}` > todaysDate.getFullYear().toString()) return true;
    return false;
  }
  getCreditCardImage(d: any) {
    switch (d.CardType) {
      case -1:
        return 'unverified bank icon.svg';
      case 1:
        return 'Visa.png';
      case 2:
        return 'MasterCard.png';
      case 3:
        return 'American Express.png';
      case 4:
        return 'diners.png';
      case 5:
        return 'Discover.png';
      case 6:
        return 'jcb-logo.jpg';
      case 8:
        return 'bank icon.svg';
    }
    return 'blank.gif';
  }
  getCardName(profile: any) {
    switch (profile.CardType) {
      case 1:
        return 'Visa';
      case 2:
        return 'MasterCard';
      case 3:
        return 'American Express';
      case 4:
        return 'diners';
      case 5:
        return 'Discover';
      case 6:
        return 'jcb-logo';
      case 7:
        return profile.NameOnCard?.replace(/\s/g, '');
    }
    return 'Unknown Card.';
  }
  getCardType(profile: PaymentProfile) {
    switch (profile.CardType) {
      case -1:
      case 8:
        return 'Account';
      default:
        return 'Card';
    }
  }
  getDefaultPaymentProfile() {
    return this.defaultPaymentProfile;
  }
  get isLoadingPaymentProfiles() {
    return this.isGettingPaymentProfiles;
  }
  get ConstValues() {
    return ConstValues;
  }
  get ccvMaxLength() {
    if (this.newPaymentModel.CreditCardNumber) {
      let regex = new RegExp('^3[4,7]\\d{13}$', 'g');
      let isAmex = regex.test(this.newPaymentModel.CreditCardNumber);
      if (isAmex) return 4;
    }
    return 3;
  }
  addNewPaymentSelected() {
    // let newWindow = window.open('', '_blank', 'height=750,width=500');
    let newWindow: Window | null = null;
    setTimeout(() => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      let url = '';
      if (isSafari) {
        url = `${process.env.VUE_APP_BASE_URL}/AddPayment?cUBA=${this.Customer.Term.Days >
          0}`;
      } else {
        url = `/AddPayment?cUBA=${this.Customer.Term.Days > 0}`;
      }
      newWindow = OpenCenteredWindow(url, 'Add Payment Method', 500, 750);
      // newWindow!.focus();
    }, 250);
    window.onfocus = async () => {
      await this.GetCustomerPaymentProfiles();
    };
  }
  /* Mounted */
  async mounted() {
    this.isAddingNewPayment = true;
    if (this.$route.query.setup_intent) {
      try {
        if (this.$route.fullPath.includes('isCartOrder')) {
          this.$router.replace({ query: { isCartOrder: 'true' } });
        } else {
          this.$router.replace({});
        }
      } catch (err) {
        this.isAddingNewPayment = false;
      }
    }
    await this.GetCustomerPaymentProfiles();
    this.isAddingNewPayment = false;
  }
  /* Created */
}
